$padding: 1em;

.cookieBanner {
  z-index: 20;
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100vw;
  box-sizing: border-box;
  padding: $padding;

  background: white;
  color: black;

  h2 {
    padding: 0;
    margin: 0;
    font-size: 1.05em;
    margin-left: 0.25em;
    margin-bottom: $padding;
    > a {
      color: black;
    }
  }

  .buttons {
    display: flex;

    > button {
      flex-grow: 1;
      flex-basis: 50%;
      font-size: 0.9rem;
      font-weight: normal;
      background: #bdd755;
      border: none;
      margin: 0;

      // Because flex gaps don't work in Safari < 14.1... 🙄
      // See: https://css-tricks.com/safari-14-1-adds-support-for-flexbox-gaps/
      margin-right: $padding;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
