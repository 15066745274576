.cookiePolicy {
  background: white;
  padding: 1em;
  text-align: center;
  overflow-y: scroll !important;

  &,
  * {
    user-select: auto !important;
    touch-action: auto !important;
  }
}
