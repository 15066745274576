@use 'sass:math';
@import 'BarcodeScanner.module';

$reticleWidth: 0.99 * $panelWidth;
$cornerThickness: 0.31rem;
$cornerSize: 2.4rem;
$expansion: 0.96;

.overlay {
  grid-column: 1/-1;
  grid-row: 2/3;
  position: relative; // 👈 for the corners
  width: $reticleWidth;
  max-height: $reticleWidth;
  height: 100%;
  border-radius: $borderRadius;
  box-shadow: 0 0 0 500vw $overlayPanelColour;
  will-change: transform;

  &.pulsing {
    animation-name: pulse;
    animation-duration: 0.6s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  &.swiping {
    > .window {
      background: linear-gradient(
        rgba(0, 0, 0, 0) 0%,
        rgba(255, 255, 255, 0.6) 66.67%,
        rgba(255, 255, 255, 0.8) 99.5%,
        rgba(255, 255, 255, 1) 99.5%,
        rgba(255, 255, 255, 1) 100%,
        rgba(0, 0, 0, 0) 100%
      );
      animation-name: swipe;
      animation-duration: 0.7s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }
  }
  > .window {
    border-radius: math.div($borderRadius, 2);
  }

  > .corner {
    position: absolute;
    width: $cornerSize;
    height: $cornerSize;
    border: $cornerThickness solid white;
  }

  &.error > .corner {
    border: $cornerThickness solid rgb(234, 18, 42);
  }

  > .corner {
    &.topLeft {
      border-radius: $borderRadius * 1.6 0 0 0;
      top: 0;
      left: 0;
      margin-top: math.div(-$cornerThickness, 2);
      margin-left: math.div(-$cornerThickness, 2);
      border-right: none;
      border-bottom: none;
    }

    &.topRight {
      border-radius: 0 $borderRadius * 1.6 0 0;
      top: 0;
      right: 0;
      margin-top: math.div(-$cornerThickness, 2);
      margin-right: math.div(-$cornerThickness, 2);
      border-left: none;
      border-bottom: none;
    }

    &.bottomRight {
      border-radius: 0 0 $borderRadius * 1.6 0;
      bottom: 0;
      right: 0;
      margin-bottom: math.div(-$cornerThickness, 2);
      margin-right: math.div(-$cornerThickness, 2);
      border-top: none;
      border-left: none;
    }

    &.bottomLeft {
      border-radius: 0 0 0 $borderRadius * 1.6;
      bottom: 0;
      left: 0;
      margin-bottom: math.div(-$cornerThickness, 2);
      margin-left: math.div(-$cornerThickness, 2);
      border-top: none;
      border-right: none;
    }
  }
}

@media (orientation: landscape) {
  .overlay {
    grid-column: 2/3;
    grid-row: 1/-1;

    width: 50vw;
    max-height: calc(100% - 2em);
    height: 100%;
  }
}

@keyframes pulse {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale($expansion);
  }
  to {
    transform: scale(1);
  }
}
@keyframes swipe {
  from {
    height: 0;
  }
  to {
    height: 100.5%;
  }
}
