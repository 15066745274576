@import '../BarcodeScanner/BarcodeScanner.module';

.loadingSpinner {
  z-index: 30;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: 0.25em solid white;
  border-radius: $borderRadius;
  width: 2.5em;
  height: 2.5em;
}
