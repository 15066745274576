.loadingPage {
  background: white;

  h2 {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    padding: 15%;
    text-align: center;
  }

  img {
    position: relative;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 14% !important;
  }
}
