@import '../../../App.module';
@import '../BarcodeScanner.module';

.controlPanel {
  grid-column: 1/-1;
  grid-row: 3/4;
  width: 1.02 * $panelWidth;
  margin: 0 auto;
  color: white;
  height: $controlPanelHeight;

  svg {
    animation-name: fadeIn;
    animation-timing-function: ease-in;
    animation-duration: 0.25s;

    width: auto;
    height: $controlPanelHeight;
    float: left;

    &:last-of-type {
      float: right;
    }
    &.manual {
      float: left;
      height: 1.143 * $controlPanelHeight;
      transform: translate(-0.053 * $controlPanelHeight, -0.1286 * $controlPanelHeight);
    }
  }
}

@media (orientation: landscape) {
  .controlPanel {
    position: relative;
    width: auto;
    height: 80%;
    grid-column: 3/4;
    grid-row: 1/-1;
    justify-self: end;

    svg {
      position: absolute;
      right: 0;
      &:last-of-type {
        bottom: 0;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
