.thankYouPage {
  background: white;
  padding: 4em 2em;

  h2 {
    font-size: 125%;
  }

  button {
    width: 100%;
  }
}
