@import '../../../App.module';
@import '../BarcodeScanner.module';

$linkColour: #0074fe;
// Slightly hacky way to turn black colour to the above colour, in an SVG!
// See: https://codepen.io/sosuke/pen/Pjoqqp
$linkColourSVGFilter: invert(27%) sepia(58%) saturate(3313%) hue-rotate(204deg) brightness(104%)
  contrast(105%);

.keyboard {
  position: absolute;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  width: 100%;
  height: 3.5em;
  padding: 0 $padding 0.33 * $padding $padding;

  box-shadow: 0 0 0 100vh $overlayPanelColour;
  box-sizing: border-box;
  background: white;

  // Alternative to `display: none`, so we can still call Element.focus()...
  z-index: -50;
  &.open {
    z-index: 50;
  }

  * {
    @include systemFont;
  }

  input {
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: rgba(60, 60, 67, 0.3);
    }
  }
  input,
  button {
    display: inline;
    margin: 0;
    padding: 0;
    border: none;
    font-size: 1.1em;
    font-weight: normal;
  }

  button {
    color: $linkColour;
    float: right;

    &:disabled {
      opacity: 0.4;
    }
  }
}
