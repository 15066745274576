@import '../ProductPage.module';

.requestButton {
  margin: 1.6em 0;
  text-align: center;

  input,
  button {
    font-size: 90%;
    margin: 0 0 1em 0;
    padding: 0.7em;
    box-sizing: border-box;
    width: 100%; 

    &:disabled {
      color: #ccc;
      border: 1px solid #ccc;
    }
  }

  label > p {
    text-align: left;
    font-size: small;
    margin-bottom: 1em;
  }

  input:disabled {
    color: #b9b9b9;
  }
}

.errorMessage {
  background-color: #FFF3F4;
  border-left: 4px solid #EA122A;
  padding: 6px 16px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.arrow {
  
  margin-left: 15px;
  width: 0;
  height: 0;
  border-bottom: 10px solid #FFF3F4;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
