$panelWidth: 86vw;
$borderRadius: 0.5rem;
$padding: 1em;
$overlayPanelColour: rgba(153, 153, 153, 0.8);
$controlPanelHeight: 2.8em;

.scanner {
  overflow: hidden;
  height: 100%;
  min-height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 25% 50% 25%;
  place-items: center;
  position: relative; // 👈 For the loading spinner

  video {
    grid-column: 1/-1;
    grid-row: 1/-1;
    place-self: stretch;
    object-fit: cover;
  }
}

@media (orientation: landscape) {
  .scanner {
    grid-template-columns: 25% 50% 25%;
    grid-template-rows: 100%;
  }
}
