@import 'BarcodeScanner.module';

.statusPanel {
  z-index: 10;
  grid-column: 1/-1;
  grid-row: 1/2;

  box-sizing: border-box;
  width: $panelWidth;
  padding: $padding;
  border-radius: $borderRadius;
  background: rgba(0, 0, 0, 0.6);
  color: white;

  svg {
    width: auto;
    height: 2.3em;
    float: left;
    margin-right: $padding;
  }

  .textWrapper {
    float: left;
    max-width: 82%;
    margin: 0;
    padding: 0;

    h2 {
      font-size: 0.94em;
      margin: 0;
      padding: 0 0 0.12em 0;
    }
    span {
      margin: 0;
      padding: 0;
      font-size: 0.8em;
    }
  }
}

@media (orientation: landscape) {
  .statusPanel {
    grid-column: 1/2;
    grid-row: 1/-1;
    width: 80%;

    svg {
      margin-bottom: 0.6em;
    }
  }
}
