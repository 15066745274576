@import '../BarcodeScanner.module';

svg {
  &.on {
    height: $controlPanelHeight;
    transform: translate(-0.04 * $controlPanelHeight, 0);
  }
  &.off {
    height: 1.214 * $controlPanelHeight;
    transform: translate(0.03 * $controlPanelHeight, -0.1071 * $controlPanelHeight);
  }
}
