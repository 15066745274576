.errorMessage {
  z-index: 30; // Cover 🍪 prompt!
  padding: 33.3% 2em 0 2em;
  text-align: center;
  color: black;
  background: white;
  svg {
    margin: 1rem;
  }
  h2 {
    margin: 2em 0;
  }
  button {
    width: 100%;
  }
}
