$lightGrey: rgb(216, 216, 216);
$headerHeight: 3.75rem;
$blur: blur(0.18rem);

@mixin systemFont {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, sans-serif;
}

.app {
  background: $lightGrey;
  width: 100vw;
  height: 100%;
  overflow: hidden;

  display: grid;
  grid-template-rows: $headerHeight calc(100% - #{$headerHeight});

  &.blurred {
    main > div,
    footer {
      filter: $blur;
    }
  }

  header {
    z-index: 40;
    height: 100%;
    background: white;
    border: 1px solid $lightGrey;

    display: grid;
    align-items: center;
  }
  main {
    display: contents;
  }
  h1,
  h2 {
    font-weight: bold;
  }
  h1 {
    font-size: 1.15rem;
    margin: 0;
    text-transform: uppercase;
    text-align: center;
  }
  h2 {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
  }
  p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
  input,
  button {
    margin-top: 1.2rem;
    padding: 0.7em 1.4em;
    border: 0.1rem solid black;
    background-color: white;
    font-size: 1.02rem;
    font-weight: bold;
    color: black;
    cursor: pointer;
  }
  input {
    margin-top: 1.2rem;
    padding: 0.7em;
    cursor: default;
  }
}
