@import '../ProductPage.module';

.sizeSelector {
  button {
    width: auto;
    height: 3em;
    margin: $buttonSpacing $buttonSpacing 0 0;
    border: 1px solid #979797;
    text-align: center;

    &.square {
      aspect-ratio: 1;
      width: 3em;
      padding: 0;
    }

    &.selected {
      background: black;
      border: 1px solid black;
      color: white;
    }

    &:disabled {
      color: $disabledBorderColour;
      @include crossedOut;
    }
  }

  .trouserCategories {
    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      minmax(calc(50% - $buttonSpacing), calc(50% - $buttonSpacing))
    );
    gap: $buttonSpacing;
    margin: 1em 0 0 0;

    > button {
      margin: 0;
      box-sizing: border-box;
    }
  }
}
