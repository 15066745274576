@keyframes ellipsis {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@mixin ellipsisAnimation {
  opacity: 0;
  animation: ellipsis 1s infinite;
  animation-timing-function: ease-out;
}

.one {
  @include ellipsisAnimation;
}
.two {
  @include ellipsisAnimation;
  animation-delay: 0.2s;
}
.three {
  @include ellipsisAnimation;
  animation-delay: 0.4s;
}
