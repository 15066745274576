$padding: 1.5em;
$disabledBorderColour: #999999;
$disabledBackgroundColour: #f5f5f5;
$buttonSpacing: 0.55em;

@mixin crossedOut {
  background: linear-gradient(
    to top left,
    $disabledBackgroundColour 0%,
    $disabledBackgroundColour calc(50% - 1px),
    $disabledBorderColour 50%,
    $disabledBackgroundColour calc(50% + 1px),
    $disabledBackgroundColour 100%
  );
}

.productPage {
  overflow-y: scroll !important;
  padding: $padding 1.333 * $padding;

  background: white;

  img {
    width: 100%;
  }

  h2 {
    line-height: 1.6em;
    margin-top: 0.5em;
  }

  h3 {
    font-size: 0.9em;
    margin-bottom: 0.1em;

    > span {
      font-size: 80%;
      border-radius: 1em;
      padding: 0.167em 0.67em;
      background: $disabledBackgroundColour;
    }
  }

  a {
    color: black;
    font-weight: bold;
    text-align: center;
    margin: 0 auto;
    display: block;
    text-underline-offset: 1px;
  }

  &,
  * {
    user-select: auto !important;
    touch-action: auto !important;
  }
}
