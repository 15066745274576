@import-normalize;

@font-face {
  font-family: 'mns-london';
  src: local('mns-london'), url(fonts/MSLondoneText-Semibold.woff2) format('woff2'),
    url(fonts/MSLondoneText-Semibold.woff) format('woff'),
    url(fonts/MSLondoneText-Semibold.eot?#iefix) format('eot');
  font-display: swap;
}

html,
body,
#root {
  width: 100vw;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: 100%; // Prevent iOS font-size change with screen orientation.
}

body,
button,
input {
  font-family: 'mns-london', -apple-system, 'Helvetica Neue', 'Helvetica', sans-serif;
  background-color: white;
  color: black;
}

* :not(input) {
  // Make it "app-like".
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  -webkit-user-select: none;
  touch-action: none;
}
